import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "aktPSPD";
const modelPath = `/aktivitas/`;

class AktivitasPSPD extends ModelBase {
  constructor() {
    let model = {
        id: null,
        perawatan: null,
        preceptor: null,
        tanggal: null,
        kompetensi: "",
        jenis_keterampilan: null
    };
    let requiredFields = ["perawatan", "preceptor", "tanggal", "kompetensi", "jenis_keterampilan"];
    super(modelName, model, requiredFields, [], modelPath);
  }
}

export default AktivitasPSPD;