<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">
            TAMBAH AKTIVITAS MAHASISWA
          </p>
        </header>
        <div class="card-content events-card">
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>

          <b-field
            label="Kode Bill"
            class="pasien-field"
            custom-class="is-small is-capitalized"
            :type="{ 'is-danger': errorMap.perawatan }"
            :message="errorMap.perawatan"
          >
            <generic-autocomplete
              placeholder="Cari sebelum buat baru"
              apiPath="/perawatan/"
              :params="{ fields_type: 'ac' }"
              v-model="aktPSPD.perawatan"
              @input="validateInput('perawatan')"
              @typing="onPerawatanACTyping"
            ></generic-autocomplete>

            <div class="control">
              <router-link
                :to="{ name: 'klinis-add', params: { aktPSPD: aktPSPD } }"
              >
                <b-button class="is-primary">
                  <b-icon icon="plus" />
                </b-button>
              </router-link>
            </div>
            <div :v-show="false">
              <!-- diperlukan agar menjadi addons, terkait permasalahan di method
              b-field.hasAddons dimana kalkulasi jumlah node di slot ini, baru button-nya
              yang terhitung. dan tidak di-watch. kemungkinan terkait race-condition.
              -->
            </div>
          </b-field>

          <b-field
            label="Tanggal"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.tanggal }"
            :message="errorMap.tanggal"
          >
            <date-picker
              v-model="aktPSPD.tanggal"
              @input="validateInput('tanggal')"
            ></date-picker>
          </b-field>

          <b-field
            label="Preceptor"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.preceptor }"
            :message="errorMap.preceptor"
          >
            <generic-autocomplete
              apiPath="/anggota/preceptor/"
              v-model="aktPSPD.preceptor"
              @input="validateInput('preceptor')"
            ></generic-autocomplete>
          </b-field>
          <b-field
            label="Kompetensi"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.kompetensi }"
            :message="errorMap.kompetensi"
          >
            <b-select
              v-model="aktPSPD.kompetensi"
              placeholder="Kompetensi"
              required
              expanded
              @input="validateInput('kompetensi')"
            >
              <option
                v-for="kompetensi in kompetensiOptions"
                :value="kompetensi"
                :key="kompetensi"
                >{{ kompetensi }}</option
              >
            </b-select>
          </b-field>
          <b-field
            label="Jenis Keterampilan"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.jenis_keterampilan }"
            :message="errorMap.jenis_keterampilan"
          >
            <generic-select
              v-model="aktPSPD.jenis_keterampilan"
              apiPath="/aktivitas/keterampilan/jenis/"
              @input="validateInput('jenis_keterampilan')"
            ></generic-select>
          </b-field>
          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>

          <div class="buttons">
            <b-button
              class="is-primary has-text-weight-semibold"
              @click.stop="cancel"
              >Batal</b-button
            >
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject"
              >Simpan</b-button
            >
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import AktivitasPSPD from "../models/createAktPelayanan.js";

export default {
  name: "AktivitasKlinisCreate",
  props: ["headerText"],
  mixins: [createUpdateMixin],
  components: {
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
  },
  data() {
    this.aktPSPDMdl = new AktivitasPSPD();
    this.objectMdl = this.aktPSPDMdl; // alias yg digunakan di cuMixin
    this.kompetensiOptions = ["Observasi", "Operator dengan Supervisi"];
    let obv = this.aktPSPDMdl.getObservables();
    obv.perawatanInput = "";
    return obv;
  },
  computed: {
    ...mapState("accounts", ["userId"]),
    ...mapState("aktivitas", { aktPSPDState: "aktPSPD" }),
  },
  methods: {
    ...mapActions("aktivitas", ["setAktPSPDState"]),
    onPerawatanACTyping(value) {
      this.perawatanInput = value;
    },
    fetchData() {
      this.aktPSPDMdl.addPayloadData({ pspd: this.userId });
      if (this.aktPSPDState) {
        this.aktPSPD = JSON.parse(JSON.stringify(this.aktPSPDState));
        this.setEdited(this.aktPSPD.edited);

        for (const field of this.aktPSPDMdl.getRequiredFields()) {
          if (this.aktPSPD[field]) {
            this.validateInput(field);
          }
        }
        this.setAktPSPDState(null);
      }
    },
    saveObject() {
      // this.aktPSPD.pspd = this.userId;
      this.aktPSPDMdl.save(this.saveContext, (respObj) => {
        this.$router.replace({
          name: "aktivitas-detail",
          params: { id: respObj.id },
        });
      });
    },
  },
  watch: {
    aktPSPD: {
      // aktPSPD berubah, update edited
      handler(newValue, oldValue) {
        if (!this.aktPSPDMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .pasien-field {
  button {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  p.help {
    display: none;
  }
}
</style>
